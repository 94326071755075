import _edecData from "./edecData.json";

export type EdecDomain = {
  domain_name: string;
  meaning_de: string;
  meaning_en: string;
  valid_from: string;
  valid_to: string;
  value: string;
};
export type EdecDomainMeaningKeysType = "meaning_de" | "meaning_en";

export type EdecCountry = {
  iso_code: string;
  name_en: string;
  name_de: string;
  valid_from: string;
  valid_to: string;
};

export type EdecCustomsOffice = {
  number: string;
  name: string;
  valid_from: string;
  valid_to: string;
};

export type EdecTariffCommodityCode = {
  valid_from: string;
  valid_to: string;
  value: string;
};

export type EdecTariffStatisticalCode = {
  valid_from: string;
  valid_to: string;
  value: number;
};

export interface EdecData {
  edec_domains: Record<string, EdecDomain[]>;
  edec_countries: EdecCountry[];
  edec_customs_offices: EdecCustomsOffice[];
  edec_tariff_commodity_code: EdecTariffCommodityCode[];
  edec_tariff_statistical_code: EdecTariffStatisticalCode[];
}

export interface EdecDataDictionary {
  edec_domains: Record<string, Record<string, EdecDomain>>;
  edec_countries: Record<string, EdecCountry>;
  edec_customs_offices: Record<string, EdecCustomsOffice>;
  edec_tariff_commodity_code: Record<string, EdecTariffCommodityCode>;
  edec_tariff_statistical_code: Record<string, EdecTariffStatisticalCode>;
}

type KeyableRecord = {
  [key: string]: unknown;
  valid_from: string;
};

export const filterAndSortEdecData = <T extends KeyableRecord>(
  edec?: T[]
): T[] | undefined => {
  const result =
    edec &&
    [...edec].sort(
      (a, b) =>
        new Date(b.valid_from).getTime() - new Date(a.valid_from).getTime()
    );

  return result;
};

const edecData = _edecData as Omit<EdecData, "edec_domains"> & {
  edec_domains: EdecDomain[];
};

const createTransformedData = <
  T extends KeyableRecord,
  K extends keyof T & string
>(
  data: T[],
  keyField: K
): T[] => {
  const sortedData = filterAndSortEdecData(data);
  return (
    sortedData?.reduce<T[]>((acc, curr) => {
      if (!acc.find((item) => item[keyField] === curr[keyField])) {
        acc.push(curr);
      }
      return acc;
    }, []) ?? []
  );
};

const transformedEdecData = {
  edec_domains: edecData.edec_domains.reduce<Record<string, EdecDomain[]>>(
    (acc, curr) => {
      const domainName = curr.domain_name!;
      if (!acc[domainName]) {
        acc[domainName] = [];
      }
      if (!acc[domainName].find((item) => item.value === curr.value)) {
        acc[domainName].push(curr);
      }
      return acc;
    },
    {}
  ),
  edec_countries: createTransformedData(edecData.edec_countries, "iso_code"),
  edec_customs_offices: createTransformedData(
    edecData.edec_customs_offices,
    "number"
  ),
  edec_tariff_commodity_code: createTransformedData(
    edecData.edec_tariff_commodity_code,
    "value"
  ),
  edec_tariff_statistical_code: createTransformedData(
    edecData.edec_tariff_statistical_code,
    "value"
  ),
} as EdecData;

const createTransformedDataDictionary = <
  T extends KeyableRecord,
  K extends keyof T & string
>(
  data: T[],
  keyField: K
): Record<string, T> => {
  const sortedData = filterAndSortEdecData(data);
  return (
    sortedData?.reduce<Record<string, T>>((acc, curr) => {
      const key = String(curr[keyField]);
      if (!acc[key]) {
        acc[key] = curr;
      }
      return acc;
    }, {}) ?? {}
  );
};

export const transformedEdecDataDictionary = {
  edec_domains:
    filterAndSortEdecData(edecData.edec_domains)?.reduce<
      Record<string, Record<string, EdecDomain>>
    >((acc, curr) => {
      const domainName = curr.domain_name!;
      if (!acc[domainName]) {
        acc[domainName] = {};
      }
      if (!acc[domainName][curr.value]) {
        acc[domainName][curr.value] = curr;
      }
      return acc;
    }, {}) ?? {},
  edec_countries: createTransformedDataDictionary(
    edecData.edec_countries,
    "iso_code"
  ),
  edec_customs_offices: createTransformedDataDictionary(
    edecData.edec_customs_offices,
    "number"
  ),
  edec_tariff_commodity_code: createTransformedDataDictionary(
    edecData.edec_tariff_commodity_code,
    "value"
  ),
  edec_tariff_statistical_code: createTransformedDataDictionary(
    edecData.edec_tariff_statistical_code,
    "value"
  ),
} as EdecDataDictionary;

export default () => {
  return {
    edecData: transformedEdecData,
    edecDataDictionary: transformedEdecDataDictionary,
  };
};
