import React, {
  useContext,
  useReducer,
  createContext,
  ReactNode,
  useMemo,
  useEffect,
  useState,
} from "react";

import { Offset, Position } from "@periplus/ui-library";
import { Declaration_Discussion } from "graphql/generated";
import useGetEdecMasterData, {
  EdecData,
} from "domain/edec/useGetEdecMasterData";

export const pathDiff = (prevPath: string, newPath: string) => {
  const prevPathArr = prevPath.split("/");
  const newPathArr = newPath.split("/");

  const diff = [] as string[];
  newPathArr.forEach((item, index) => {
    if (item === prevPathArr[index]) {
      return;
    }

    diff.push(item);
  });

  return diff.join(" ");
};

export const isPathDiffUuid = (prevPath: string, newPath?: string) => {
  const diff = newPath ? pathDiff(prevPath, newPath) : prevPath;

  const uuidRegex = new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  );
  return uuidRegex.test(diff);
};

enum ReducerActions {
  toggleShowNotifications = "toggleShowNotifications",
  toggleGotNewMessages = "toggleGotNewMessages",
  showChat = "showChat",
  changeChatProperties = "changeChatProperties",
  closeChat = "closeChat",
  hideChat = "hideChat",
  setChatNewMessages = "setChatNewMessages",
  saveLocation = "saveLocation",
  lastUploadedFile = "lastUploadedFile",
}

type NotificationsPayload = { isNotificationsShown?: boolean };
type NewMessagePayload = { isNewMessages?: boolean };
type ChatPayload = {
  fileId?: string;
  discussionId?: Declaration_Discussion["discussion_id"];
  title?: string;
  subTitle?: string;
  options?: {
    offset?: Offset;
    position?: Position;
  };
};
type ChatState = {
  chat: {
    fileId?: string;
    show: boolean;
    lastUpdateDate: number;
    discussionId?: number;
    title?: string;
    subTitle?: string;
    options?: {
      offset?: Offset;
      position?: Position;
    };
  };
};
type ChatNewMessagesPayload = {
  file_name: string;
  liber_user: string;
  last_msg_date: string;
  file_id: string;
  discussion_theme: string;
  discussion_id: number;
  adit_user: string;
  reference: string;
};
type ChatNewMessagesState = {
  chatNewMessages: {
    file_name: string;
    discussion_theme: string;
    discussion_id: number;
    reference: string;
  }[];
};
type Location = {
  location: string;
  previousLocation: string;
  previousLink: string;
};
type FileUploadPayload = {
  lastUploadedDocumentId?: string;
};
type LocationToSave = { location: string };
type Action = {
  type: ReducerActions;
  payload?:
    | NotificationsPayload
    | NewMessagePayload
    | ChatPayload
    | ChatNewMessagesPayload[]
    | LocationToSave
    | FileUploadPayload;
};
type Dispatchers = {
  toggleNotificationsShown: (state?: boolean) => void;
  toggleNewMessages: (state?: boolean) => void;
  showChat: (variables: ChatPayload) => void;
  changeChatProperties: (variables: ChatPayload) => void;
  closeChat: () => void;
  hideChat: () => void;
  setChatNewMessages: (variables: ChatNewMessagesPayload[]) => void;
  saveLocation: (location: string) => void;
  lastUploadedFile: (lastUploadedDocumentId: string) => void;
};
type State = Required<NotificationsPayload> &
  NewMessagePayload &
  ChatState &
  ChatNewMessagesState &
  Location & { edecData: EdecData };
type CountProviderProps = { children: ReactNode };

const NavigationStateContext = createContext<State | undefined>(undefined);
const NavigationDispatchContext = createContext<Dispatchers | undefined>(
  undefined
);

const chatDateKey = "chatSeen";

const LOCATION_KEY = "lastLocation";
const ApplicationReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ReducerActions.toggleShowNotifications: {
      let { isNotificationsShown } = action.payload as NotificationsPayload;

      if (isNotificationsShown === undefined) {
        isNotificationsShown = !state.isNotificationsShown;
      }

      return {
        ...state,
        isNotificationsShown,
      };
    }
    case ReducerActions.toggleGotNewMessages: {
      let { isNewMessages } = action.payload as NewMessagePayload;

      if (isNewMessages === undefined) {
        isNewMessages = !state.isNewMessages;
      }

      return {
        ...state,
        isNewMessages,
      };
    }
    case ReducerActions.showChat: {
      return {
        ...state,
        chat: {
          ...state.chat,
          show: true,
          lastUpdateDate: state.chat.lastUpdateDate,
          ...action.payload,
          options: {
            // @ts-ignore
            ...action.payload.options,

            ...(state.chatNewMessages.length > 0
              ? {
                  offset: {
                    right: 70,
                  },
                }
              : {}),
          },
        },
      };
    }
    case ReducerActions.changeChatProperties: {
      return {
        ...state,
        chat: Object.assign(state.chat, action.payload),
      };
    }
    case ReducerActions.closeChat: {
      const date = new Date();
      const dateInSeconds = date.getTime();
      const openChats = state.chatNewMessages.filter(
        (chats) => chats.discussion_id !== state.chat.discussionId
      );

      let lastUpdateDate = dateInSeconds;
      if (openChats.length === 0) {
        localStorage.setItem(chatDateKey, dateInSeconds.toString());
      }

      return {
        ...state,
        chat: {
          show: false,
          lastUpdateDate,
        },
        chatNewMessages: openChats,
      };
    }
    case ReducerActions.hideChat: {
      let updatedChatNewMessages = state.chatNewMessages.filter(
        (message) => message.discussion_id !== state.chat.discussionId
      );
      if (state.chat.subTitle && state.chat.discussionId && state.chat.title) {
        updatedChatNewMessages = updatedChatNewMessages.concat({
          file_name: state.chat.title,
          discussion_theme: state.chat.subTitle,
          discussion_id: state.chat.discussionId,
          reference: state.chat.title,
        });
      }

      return {
        ...state,
        chat: {
          ...state.chat,
          show: false,
          options: {
            ...state.chat.options,
            offset: {
              right: 70,
            },
          },
        },
        chatNewMessages: updatedChatNewMessages,
      };
    }
    case ReducerActions.setChatNewMessages: {
      const chatNewMessages = action.payload as ChatNewMessagesPayload[];
      let updatedChatNewMessages = chatNewMessages.map(
        ({ file_name, discussion_theme, discussion_id, reference }) => ({
          file_name,
          discussion_theme,
          discussion_id,
          reference,
        })
      );
      const localStorageUpdateDate = localStorage.getItem(chatDateKey) || "0";
      if (+localStorageUpdateDate !== state.chat.lastUpdateDate) {
        updatedChatNewMessages = state.chatNewMessages.concat(
          chatNewMessages as never[]
        );
      }

      return {
        ...state,
        chat: {
          ...state.chat,
          options: {
            ...state.chat.options,
            offset: {
              right: 70,
            },
          },
        },
        chatNewMessages: updatedChatNewMessages,
      };
    }
    case ReducerActions.saveLocation: {
      const { location } = action.payload as LocationToSave;
      const diff = pathDiff(state.location, location);
      const isUuid = isPathDiffUuid(diff);

      let previousLocation = state.previousLocation;
      if (state.location && !isUuid) {
        sessionStorage.setItem(LOCATION_KEY, state.location);
        previousLocation = state.location;
      }

      return {
        ...state,
        location,
        /*
          We try to keep previous location in such cases:
            1. If application was load first time and we set previousLocation
                from sessionStorage in state directly (const prevLocation = sessionStorage.getItem(LOCATION_KEY););
            2. If page reloads more then 1 time, we should save correct location
                (there is no reason to have location and previousLocation the same);
            3. If we on the same page but only change ID of items we see
          */
        previousLocation: previousLocation,
        /*
          Real previous place we come from even if we only go through ids
        */
        previousLink: diff.length > 0 ? state.location : state.previousLink,
      };
    }
    case ReducerActions.lastUploadedFile:
      return {
        ...state,
        lastUploadedDocumentId: (action.payload as FileUploadPayload)
          .lastUploadedDocumentId,
      };
    default: {
      return state;
    }
  }
};

const prevLocation = sessionStorage.getItem(LOCATION_KEY);
const useApplicationReducer = (): [State, Dispatchers] => {
  const { edecData } = useGetEdecMasterData();
  const date = new Date();
  const localStorageDate =
    localStorage.getItem(chatDateKey) || `${date.getTime()}`;

  const [initialDateTime] = useState(+localStorageDate);
  const [state, dispatch] = useReducer(ApplicationReducer, {
    isNotificationsShown: false,
    isNewMessages: false,
    chat: {
      show: false,
      lastUpdateDate: initialDateTime,
    },
    chatNewMessages: [],
    location: "",
    previousLocation: prevLocation || "",
    previousLink: prevLocation || "",
    lastUploadedDocumentId: undefined,
    edecData,
  });

  useEffect(() => {
    const storageDate = localStorage.getItem(chatDateKey) || "0";
    if (initialDateTime !== +storageDate) {
      localStorage.setItem(chatDateKey, initialDateTime.toString());
    }
  }, [initialDateTime]);

  const actions = useMemo(
    () => ({
      toggleNotificationsShown: (notificationsShownState?: boolean) => {
        dispatch({
          type: ReducerActions.toggleShowNotifications,
          payload: { isNotificationsShown: notificationsShownState },
        });
      },
      toggleNewMessages: (newMessagesState?: boolean) => {
        dispatch({
          type: ReducerActions.toggleGotNewMessages,
          payload: { isNewMessages: newMessagesState },
        });
      },
      showChat: (variables: ChatPayload) => {
        const cleanedOptions = {
          ...(variables.options || {}),
          // small cleanup of offset to prevent caching previous offset values
          offset: Object.assign(
            {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
            variables?.options?.offset
          ),
        };

        dispatch({
          type: ReducerActions.showChat,
          payload: {
            ...variables,
            options: cleanedOptions,
          },
        });
      },
      changeChatProperties: (variables: ChatPayload) => {
        dispatch({
          type: ReducerActions.changeChatProperties,
          payload: {
            ...variables,
          },
        });
      },
      closeChat: () => {
        dispatch({
          type: ReducerActions.closeChat,
        });
      },
      hideChat: () => {
        dispatch({
          type: ReducerActions.hideChat,
        });
      },
      setChatNewMessages: (variables: ChatNewMessagesPayload[]) => {
        dispatch({
          type: ReducerActions.setChatNewMessages,
          payload: variables,
        });
      },
      saveLocation: (location: string) => {
        dispatch({
          type: ReducerActions.saveLocation,
          payload: { location },
        });
      },
      lastUploadedFile: (lastUploadedDocumentId: string) => {
        dispatch({
          type: ReducerActions.lastUploadedFile,
          payload: { lastUploadedDocumentId },
        });
      },
    }),
    []
  );

  return [state, actions];
};

const ApplicationProvider = ({ children }: CountProviderProps) => {
  const [state, actions] = useApplicationReducer();

  return (
    <NavigationStateContext.Provider value={state}>
      <NavigationDispatchContext.Provider value={actions}>
        {children}
      </NavigationDispatchContext.Provider>
    </NavigationStateContext.Provider>
  );
};

const useApplicationState = () => {
  const context = useContext(NavigationStateContext);
  if (context === undefined) {
    throw new Error(
      "useNavigationState must be used within a NavigationProvider"
    );
  }
  return context;
};

const useApplicationAction = () => {
  const context = useContext(NavigationDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useNavigationAction must be used within a NavigationProvider"
    );
  }
  return context;
};

const useApplicationContext = (): [State, Dispatchers] => [
  useApplicationState(),
  useApplicationAction(),
];

export {
  ApplicationProvider,
  useApplicationAction,
  useApplicationState,
  useApplicationContext,
};
